<template>
    <v-dialog :value="true" max-width="800" persistent>
        <v-card>
            <v-form ref="form" @submit.prevent="onCreate">
                <v-card-text v-if="isLoading" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{ $t("associations.association.historyDialog.isLoading") }}
                </v-card-text>
                <v-card-text v-else-if="error" class="text-center">
                    <v-alert class="pa-0 error--text">
                        {{ $t("associations.association.historyDialog.error") }}
                    </v-alert>
                </v-card-text>
                <v-card-text v-else>
                    <h1 class="text-center my-2">
                        {{ $t("associations.association.historyDialog.title") }}
                    </h1>
                    <v-select
                        v-if="
                            historyFields.types &&
                                historyFields.types.length > 1
                        "
                        v-model="newLine.interaction_type_name"
                        :label="
                            $t('associations.association.history.type') + '*'
                        "
                        :rules="requiredSelectRule"
                        :items="historyFields.types"
                        :disabled="!!line"
                    >
                    </v-select>

                    <span v-if="newLine.interaction_type_name">
                        <v-text-field
                            v-model="newLine.date"
                            :label="
                                $t('associations.association.history.date') +
                                    '*'
                            "
                            :rules="requiredRule"
                            type="date"
                        ></v-text-field>

                        <v-text-field
                            v-model="newLine.lieu"
                            :label="
                                $t('associations.association.history.place') +
                                    '*'
                            "
                            :rules="requiredRule"
                        ></v-text-field>

                        <v-select
                            v-model="newLine.type_dorchestre"
                            :label="
                                $t(
                                    'associations.association.history.orchestraType'
                                ) + '*'
                            "
                            :rules="requiredRule"
                            :items="historyFields.orchestraTypes"
                        >
                        </v-select>

                        <v-select
                            v-model="newLine.division"
                            :label="
                                $t(
                                    'associations.association.history.division'
                                ) + '*'
                            "
                            :rules="requiredRule"
                            :items="historyFields.divisions"
                        >
                        </v-select>

                        <v-select
                            v-model="newLine.mention_obtenue"
                            :label="
                                $t('associations.association.history.mention') +
                                    '*'
                            "
                            :rules="requiredRule"
                            :items="historyFields.mentions"
                        >
                        </v-select>

                        <v-textarea
                            v-model="newLine.comment"
                            rows="2"
                            :label="
                                $t('associations.association.history.comment')
                            "
                        ></v-textarea>
                    </span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="this.line !== undefined"
                        color="primary"
                        type="submit"
                        :loading="isLoading"
                    >
                        {{ $t("button.update") }}
                    </v-btn>
                    <v-btn
                        v-else
                        color="primary"
                        type="submit"
                        :loading="isLoading"
                    >
                        {{ $t("button.add") }}
                    </v-btn>
                    <v-btn @click="onClose">
                        {{ $t("button.return") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
    props: {
        line: {
            type: Object,
        },

        associationId: {
            type: [String, Number],
            required: true,
        },

        historyFields: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            newLine: this.line,

            isLoading: false,
            error: false,
        };
    },

    created() {
        if (this.newLine == undefined) {
            this.newLine = {
                interaction_type_name: null,
                date: moment().format("YYYY-MM-DD"),
                lieu: null,
                type_dorchestre: null,
                division: null,
                mention_obtenue: null,
                comment: null,
            };
            if (this.historyFields.types.length == 1) {
                this.newLine.interaction_type_name = this.historyFields.types[0];
            }
        } else {
            if (
                this.historyFields.types &&
                this.historyFields.types.length == 1
            ) {
                this.newLine.interaction_type_name = this.historyFields.types[0];
            }
            this.newLine.date = moment(this.newLine.date).format("YYYY-MM-DD");
        }
    },

    mounted() {
        if (this.line !== undefined) {
            this.$refs.form.validate();
        }
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            requiredSelectRule: (state) => state.utils.requiredSelectRule,
        }),
    },

    methods: {
        onCreate() {
            if (this.$refs.form.validate()) {
                if (this.line !== undefined) {
                    this.updateHistoryLine();
                } else {
                    this.createHistoryLine();
                }
            }
        },

        createHistoryLine() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/createAssociationHistoryLine", {
                    associationId: this.associationId,
                    form: this.newLine,
                })
                .then(() => {
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isLoading = false;
                    this.error = true;
                });
        },

        updateHistoryLine() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/updateAssociationHistoryLine", {
                    associationId: this.associationId,
                    historyLineId: this.line.id,
                    form: this.newLine,
                })
                .then(() => {
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isLoading = false;
                    this.error = true;
                });
        },

        onClose() {
            this.$emit("close");
        },
    },
};
</script>
