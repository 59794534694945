<template>
    <loader
        :isLoading="isLoading"
        :error="error"
        errorMessage="associations.showError"
    >
        <h1 class="text-center">{{ association.name }}</h1>
        <p class="text-center mb-4">
            {{ $t("associations.association.year", { year: year }) }}
        </p>
        <v-card outlined color="primary" class="mt-2">
            <v-card-text>
                <h2 class="text-center mb-4">
                    {{ $t("associations.association.informations") }}
                    <v-btn
                        v-if="canUpdate"
                        text
                        :to="{
                            name: 'UpdateAssociation',
                            params: {
                                associationId: associationId,
                            },
                        }"
                    >
                        <v-icon small color="primary">
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </h2>

                <v-row class="mb-2">
                    <v-col cols="12" md="">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <p class="mb-0" v-if="association.address">
                                    <strong>
                                        <v-icon dense>
                                            mdi-mailbox-open-outline
                                        </v-icon>
                                        {{
                                            $t(
                                                "associations.association.address.street"
                                            )
                                        }}
                                        :
                                    </strong>
                                    {{ association.address.street }}
                                    {{ association.address.post_code }}
                                    {{ association.address.city }}
                                </p>
                                <p class="mb-0">
                                    <strong>
                                        <v-icon dense>
                                            mdi-phone
                                        </v-icon>
                                        {{
                                            $t("associations.association.phone")
                                        }}
                                        :
                                    </strong>
                                    {{ association.phone }}
                                </p>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <p class="mb-0" v-if="association.type">
                                    <strong>
                                        <v-icon dense>
                                            mdi-bookmark-music
                                        </v-icon>
                                        {{
                                            $t("associations.association.type")
                                        }}
                                        :
                                    </strong>
                                    {{ association.type.join(", ") }}
                                </p>
                                <p class="mb-0" v-if="association.federation">
                                    <strong>
                                        <v-icon dense>
                                            mdi-home-group
                                        </v-icon>
                                        {{
                                            $t(
                                                "associations.association.federation"
                                            )
                                        }}
                                        :
                                    </strong>
                                    {{ association.federation.name }}
                                </p>
                                <p class="mb-0" v-if="association.siret">
                                    <strong>
                                        <v-icon dense>
                                            mdi-card-bulleted
                                        </v-icon>
                                        {{
                                            $t("associations.association.siret")
                                        }}
                                        :
                                    </strong>
                                    {{ association.siret }}
                                </p>
                                <p
                                    class="mb-0"
                                    v-if="!isLoadingBfMag && canUpdate && !errorBfMag"
                                >
                                    <strong>
                                        <v-icon dense>
                                            mdi-book-open-variant
                                        </v-icon>
                                        {{
                                            $t("associations.association.bfMag")
                                        }}
                                        :
                                    </strong>
                                    {{ bfMagNb }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        class="text-center"
                        cols="12"
                        md="auto"
                        v-if="canUpdate && membershipYear"
                    >
                        <v-chip
                            color="success"
                            class="ma-1"
                            v-if="membershipValidate"
                        >
                            {{
                                $t(
                                    "associations.association.membershipValidate",
                                    { year: membershipYear }
                                )
                            }}
                        </v-chip>
                        <v-chip color="warning" class="ma-1" v-else>
                            {{
                                $t(
                                    "associations.association.membershipPending",
                                    { year: membershipYear }
                                )
                            }}
                        </v-chip>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                        v-for="board in boards"
                        :key="board"
                    >
                        <v-card outlined class="mt-2" v-if="$data[board]">
                            <v-card-title class="pb-0">
                                {{ $t("associations.association." + board) }}
                            </v-card-title>
                            <v-card-text>
                                <v-icon dense>
                                    mdi-badge-account-horizontal
                                </v-icon>
                                <router-link
                                    v-if="canUpdate && $data[board]"
                                    class="mr-2 primary--text text-decoration-none"
                                    :to="{
                                        name: 'Member',
                                        params: {
                                            memberId: $data[board].id,
                                        },
                                    }"
                                >
                                    {{ $data[board].firstname }}
                                    {{ $data[board].lastname }}
                                </router-link>
                                <span v-else>
                                    {{ $data[board].firstname }}
                                    {{ $data[board].lastname }}
                                </span>
                                <br />

                                <v-icon dense>
                                    mdi-at
                                </v-icon>
                                {{ $data[board].email }}
                                <br />

                                <v-icon dense>mdi-phone</v-icon>
                                {{ $data[board].phone }}
                                <br />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card
            v-if="canUpdate || canUpdateHistory"
            outlined
            color="primary"
            class="mt-3"
        >
            <v-card-text>
                <h2 class="text-center mb-4">
                    {{ $t("associations.association.members") }}
                </h2>

                <v-data-table
                    class="mt-4"
                    :headers="headersMembres"
                    :items="members"
                    disable-pagination
                    hide-default-footer
                    @click:row="onShowMember"
                    mobile-breakpoint="0"
                >
                </v-data-table>
            </v-card-text>
        </v-card>

        <association-history
            v-if="(canUpdate || canUpdateHistory) && !isLoadingHistoryFields"
            :associationId="associationId"
            :history="history"
            :historyFields="historyFields"
            :canUpdateHistory="canUpdateHistory"
            @refresh="getAssociation()"
        >
        </association-history>
    </loader>
</template>

<script>
import Loader from "@/components/utils/Loader";
import AssociationHistory from "@/components/associations/AssociationHistory";

export default {
    components: {
        Loader,
        AssociationHistory,
    },

    props: {
        associationId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            canUpdate: false, //peut également voir le nombre d'abonnement bf mag et le status de l'adhésion
            canUpdateHistory: false,
            year: "",
            association: {},
            members: [],
            history: [],
            membershipYear: null,
            membershipValidate: null,

            boards: ["president", "director", "contact"],
            president: null,
            director: null,
            contact: null,

            isLoadingAssociation: true,
            errorAssociation: false,

            headersMembres: [
                {
                    text: this.$t("members.member.lastName"),
                    value: "lastname",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("members.member.firstName"),
                    value: "firstname",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("members.member.instruments"),
                    value: "instruments",
                    class: "primary white--text",
                },
                {
                    text: this.$t("members.member.positions"),
                    value: "positions",
                    class: "primary white--text",
                },
            ],

            isLoadingHistoryFields: true,
            errorHistoryFields: false,
            historyFields: {},

            isLoadingBfMag: true,
            errorBfMag: false,
            bfMagNb: 0,
        };
    },

    mounted() {
        this.getAssociation();
    },

    computed: {
        isLoading() {
            return this.isLoadingAssociation || this.isLoadingHistoryFields;
        },

        error() {
            return this.errorAssociation || this.errorHistoryFields;
        },
    },

    methods: {
        getAssociation() {
            this.isLoadingAssociation = true;
            this.errorAssociation = false;

            this.$store
                .dispatch("ohme/getAssociation", this.associationId)
                .then((data) => {
                    this.canUpdate = data.data.canUpdate;
                    this.canUpdateHistory = data.data.canUpdateHistory;
                    this.getCreateHistoryFields();

                    this.canShowAssociationMember =
                        data.data.canShowAssociationMember;
                    this.year = data.data.year;
                    this.association = data.data.association;
                    this.members = data.data.members;
                    this.history = data.data.history;

                    this.president = this.members.find((item) => {
                        return item.positions.includes("Président(e)");
                    });
                    this.director = this.members.find((item) => {
                        return item.positions.includes("Directeur(trice)");
                    });
                    this.contact = this.members.find((item) => {
                        return item.positions.includes("Correspondant(e)");
                    });
                    this.isLoadingAssociation = false;

                    this.getMembershipStatus();
                    this.getBfMag();
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 404
                    ) {
                        this.$router.push({ name: "404" });
                    } else {
                        this.isLoadingAssociation = false;
                        this.errorAssociation = true;
                    }
                });
        },

        getMembershipStatus() {
            if (this.year) {
                this.$store
                    .dispatch("ohme/getAssociationMembershipStatus", {
                        associationId: this.associationId,
                        year: this.year,
                    })
                    .then((data) => {
                        this.membershipValidate = data.data.validate;
                        this.membershipYear = data.data.year;
                    })
                    .catch(() => {});
            }
        },

        getBfMag() {
            if (this.year) {
                this.isLoadingBfMag = true;
                this.errorBfMag = false;
                this.bfMagNb = 0;

                this.$store
                    .dispatch("ohme/getAssociationBfMag", {
                        associationId: this.associationId,
                        year: this.year,
                    })
                    .then((data) => {
                        this.bfMagNb = data.data.nb;
                        this.isLoadingBfMag = false;
                    })
                    .catch(() => {
                        this.isLoadingBfMag = false;
                        this.errorBfMag = true;
                    });
            }
        },

        getCreateHistoryFields() {
            if (this.canUpdateHistory) {
                this.isLoadingHistoryFields = true;
                this.errorHistoryFields = false;

                this.$store
                    .dispatch(
                        "ohme/getCreateAssociationHistoryLine",
                        this.associationId
                    )
                    .then((data) => {
                        this.historyFields = data.data;
                        this.isLoadingHistoryFields = false;
                    })
                    .catch(() => {
                        this.isLoadingHistoryFields = false;
                        this.errorHistoryFields = true;
                    });
            } else {
                this.historyFields = {};
                this.isLoadingHistoryFields = false;
                this.errorHistoryFields = false;
            }
        },

        onShowMember(member) {
            if (this.canShowAssociationMember) {
                this.$router.push({
                    name: "Member",
                    params: {
                        memberId: member.id,
                    },
                });
            }
        },

        onCreateHistory() {
            this.dialogLine = null;
            this.associationHistoryDialog = true;
        },

        onUpdateHistoryLine(historyLine) {
            this.dialogLine = historyLine;
            this.associationHistoryDialog = true;
        },

        onDeleteHistoryLine(historyLine) {
            this.dialogLine = historyLine;
            this.deleteHistoryDialog = true;
        },

        deleteHistoryLine() {
            this.isLoadingDeleteHistory = true;
            this.errorDeleteHistory = false;

            this.$store
                .dispatch("ohme/deleteAssociationHistoryLine", {
                    associationId: this.associationId,
                    historyLineId: this.dialogLine.id,
                })
                .then(() => {
                    this.isLoadingDeleteHistory = false;
                    this.deleteHistoryDialog = false;
                    this.refreshHistory();
                })
                .catch(() => {
                    this.errorDeleteHistory = true;
                    this.isLoadingDeleteHistory = false;
                });
        },

        refreshHistory() {
            this.getAssociation();
            this.associationHistoryDialog = false;
        },
    },
};
</script>
