var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"max-width":"800","persistent":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onCreate.apply(null, arguments)}}},[(_vm.isLoading)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t("associations.association.historyDialog.isLoading"))+" ")],1):(_vm.error)?_c('v-card-text',{staticClass:"text-center"},[_c('v-alert',{staticClass:"pa-0 error--text"},[_vm._v(" "+_vm._s(_vm.$t("associations.association.historyDialog.error"))+" ")])],1):_c('v-card-text',[_c('h1',{staticClass:"text-center my-2"},[_vm._v(" "+_vm._s(_vm.$t("associations.association.historyDialog.title"))+" ")]),(
                        _vm.historyFields.types &&
                            _vm.historyFields.types.length > 1
                    )?_c('v-select',{attrs:{"label":_vm.$t('associations.association.history.type') + '*',"rules":_vm.requiredSelectRule,"items":_vm.historyFields.types,"disabled":!!_vm.line},model:{value:(_vm.newLine.interaction_type_name),callback:function ($$v) {_vm.$set(_vm.newLine, "interaction_type_name", $$v)},expression:"newLine.interaction_type_name"}}):_vm._e(),(_vm.newLine.interaction_type_name)?_c('span',[_c('v-text-field',{attrs:{"label":_vm.$t('associations.association.history.date') +
                                '*',"rules":_vm.requiredRule,"type":"date"},model:{value:(_vm.newLine.date),callback:function ($$v) {_vm.$set(_vm.newLine, "date", $$v)},expression:"newLine.date"}}),_c('v-text-field',{attrs:{"label":_vm.$t('associations.association.history.place') +
                                '*',"rules":_vm.requiredRule},model:{value:(_vm.newLine.lieu),callback:function ($$v) {_vm.$set(_vm.newLine, "lieu", $$v)},expression:"newLine.lieu"}}),_c('v-select',{attrs:{"label":_vm.$t(
                                'associations.association.history.orchestraType'
                            ) + '*',"rules":_vm.requiredRule,"items":_vm.historyFields.orchestraTypes},model:{value:(_vm.newLine.type_dorchestre),callback:function ($$v) {_vm.$set(_vm.newLine, "type_dorchestre", $$v)},expression:"newLine.type_dorchestre"}}),_c('v-select',{attrs:{"label":_vm.$t(
                                'associations.association.history.division'
                            ) + '*',"rules":_vm.requiredRule,"items":_vm.historyFields.divisions},model:{value:(_vm.newLine.division),callback:function ($$v) {_vm.$set(_vm.newLine, "division", $$v)},expression:"newLine.division"}}),_c('v-select',{attrs:{"label":_vm.$t('associations.association.history.mention') +
                                '*',"rules":_vm.requiredRule,"items":_vm.historyFields.mentions},model:{value:(_vm.newLine.mention_obtenue),callback:function ($$v) {_vm.$set(_vm.newLine, "mention_obtenue", $$v)},expression:"newLine.mention_obtenue"}}),_c('v-textarea',{attrs:{"rows":"2","label":_vm.$t('associations.association.history.comment')},model:{value:(_vm.newLine.comment),callback:function ($$v) {_vm.$set(_vm.newLine, "comment", $$v)},expression:"newLine.comment"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(this.line !== undefined)?_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t("button.update"))+" ")]):_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")]),_c('v-btn',{on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("button.return"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }