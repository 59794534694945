<template>
    <v-card outlined color="primary" class="mt-3">
        <v-card-text>
            <h2 class="text-center mb-4">
                <v-row>
                    <v-col>
                        {{ $t("associations.association.history.title") }}
                    </v-col>
                    <v-col
                        cols="auto"
                        class="text-right"
                        v-if="canUpdateHistory"
                    >
                        <v-btn
                            color="success"
                            @click="onCreateHistory()"
                            class="mr-2"
                        >
                            {{
                                $t(
                                    "associations.association.history.createHistory"
                                )
                            }}
                        </v-btn>
                    </v-col>
                </v-row>
            </h2>

            <v-data-table
                class="mt-4"
                :headers="headersHistory"
                :items="history"
                hide-default-footer
                mobile-breakpoint="0"
                :items-per-page="10"
                :page.sync="historyPage"
                @page-count="historyCount = $event"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
            >
                <template v-slot:[`item.date`]="{ item }">
                    {{ getDate(item.date) }}
                </template>
                <template
                    v-slot:[`item.edit`]="{ item }"
                    v-if="canUpdateHistory"
                >
                    <span v-if="canUpdateHistoryLine(item)">
                        <v-icon @click="onUpdateHistoryLine(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon @click="onDeleteHistoryLine(item)">
                            mdi-delete
                        </v-icon>
                    </span>
                </template>
            </v-data-table>
            <v-pagination
                v-model="historyPage"
                :length="historyCount"
            ></v-pagination>
        </v-card-text>

        <association-history-dialog
            v-if="associationHistoryDialog"
            :line="dialogLine !== null ? dialogLine : undefined"
            :associationId="associationId"
            :historyFields="historyFields"
            @close="associationHistoryDialog = false"
            @refresh="refreshHistory()"
        ></association-history-dialog>

        <v-dialog v-model="deleteHistoryDialog" max-width="290">
            <v-card>
                <v-card-title>
                    {{
                        $t(
                            "associations.association.history.deleteDialog.title"
                        )
                    }}
                </v-card-title>

                <v-card-text v-if="isLoadingDeleteHistory" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{
                        $t(
                            "associations.association.history.deleteDialog.isLoading"
                        )
                    }}
                </v-card-text>
                <v-card-text v-else>
                    {{
                        $t("associations.association.history.deleteDialog.text")
                    }}
                    <v-alert v-if="errorDeleteHistory" class="pa-0 error--text">
                        {{
                            $t(
                                "associations.association.history.deleteDialog.error"
                            )
                        }}
                    </v-alert>
                </v-card-text>

                <v-card-actions v-if="!isLoadingDeleteHistory">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteHistoryLine()">
                        {{ $t("button.yes") }}
                    </v-btn>
                    <v-btn text @click="deleteHistoryDialog = false">
                        {{ $t("button.no") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import AssociationHistoryDialog from "@/components/associations/AssociationHistoryDialog";
import moment from "moment";

export default {
    components: {
        AssociationHistoryDialog,
    },

    props: {
        associationId: {
            type: [String, Number],
            required: true,
        },

        history: {
            type: Array,
            required: true,
        },

        historyFields: {
            type: Object,
            required: true,
        },

        canUpdateHistory: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            headersHistory: [
                {
                    text: this.$t("associations.association.history.date"),
                    value: "date",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("associations.association.history.type"),
                    value: "interaction_type_name",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("associations.association.history.place"),
                    value: "lieu",
                    class: "primary white--text",
                },
                {
                    text: this.$t(
                        "associations.association.history.orchestraType"
                    ),
                    value: "type_dorchestre",
                    class: "primary white--text",
                },
                {
                    text: this.$t("associations.association.history.division"),
                    value: "division",
                    class: "primary white--text",
                },
                {
                    text: this.$t("associations.association.history.mention"),
                    value: "mention_obtenue",
                    class: "primary white--text",
                },
                {
                    text: this.$t("associations.association.history.comment"),
                    value: "comment",
                    class: "primary white--text",
                },
            ],
            historyPage: 1,
            historyCount: 0,
            associationHistoryDialog: false,
            dialogLine: null,
            sortBy: "date",
            sortDesc: true,

            deleteHistoryDialog: false,
            isLoadingDeleteHistory: false,
            errorDeleteHistory: false,
        };
    },

    mounted() {
        if (this.canUpdateHistory) {
            this.headersHistory.push({
                text: "",
                value: "edit",
                class: "primary white--text",
                width: "100px",
            });
        }
    },

    methods: {
        canUpdateHistoryLine(item) {
            return this.historyFields.types.includes(
                item.interaction_type_name
            );
        },

        onCreateHistory() {
            this.dialogLine = null;
            this.associationHistoryDialog = true;
        },

        onUpdateHistoryLine(historyLine) {
            this.dialogLine = Object.assign({}, historyLine);
            this.associationHistoryDialog = true;
        },

        onDeleteHistoryLine(historyLine) {
            this.dialogLine = Object.assign({}, historyLine);
            this.deleteHistoryDialog = true;
        },

        deleteHistoryLine() {
            this.isLoadingDeleteHistory = true;
            this.errorDeleteHistory = false;

            this.$store
                .dispatch("ohme/deleteAssociationHistoryLine", {
                    associationId: this.associationId,
                    historyLineId: this.dialogLine.id,
                })
                .then(() => {
                    this.isLoadingDeleteHistory = false;
                    this.deleteHistoryDialog = false;
                    this.refreshHistory();
                })
                .catch(() => {
                    this.errorDeleteHistory = true;
                    this.isLoadingDeleteHistory = false;
                });
        },

        getDate(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY");
            }
        },

        refreshHistory() {
            this.associationHistoryDialog = false;
            this.$emit("refresh");
        },
    },
};
</script>
